import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import "bootstrap/dist/css/bootstrap.min.css"

import "./layout.scss"

import twitter from "../images/twitter.svg"
import facebook from "../images/facebook.svg"
import instagram from "../images/instagram.svg"
import linkedin from "../images/linkedin.svg"
import youtube from "../images/youtube.svg"

export default function Footer() {
  return (
    <footer className="pt-5 pb-5">
      <Container className="pt-5">
        <Row id="contact">
          <Col>
            <div className="item">
              <h2>send us an email</h2>
              <p>
                <a href="mailto:info@saldrey.com">info@saldrey.com</a>
              </p>
            </div>
            <div className="item">
              <h2>call us</h2>
              <p className="tel">
                <a href="tel:+2348035399473">+2348035399473</a>,{" "}
                <a href="tel:+2348150553037">+2348150553037</a>,{" "}
                <a href="tel:+447392497595">+447392497595</a>
              </p>
            </div>
            <div className="item">
              <h2>find us</h2>
              <p>No 2B Deji Odunuga Street, Anthony, Maryland, Lagos</p>
            </div>
            <div className="item">
              <h2>follow us</h2>
              <p>
                <a href="https://twitter.com/">
                  <img
                    src={twitter}
                    alt="twitter"
                    width="24"
                    className="d-inline-block align-top mr-3"
                  />
                </a>
                <a href="https://facebook.com/">
                  <img
                    src={facebook}
                    alt="facebook"
                    width="24"
                    className="d-inline-block align-top mr-3"
                  />
                </a>
                <a href="https://instagram.com/">
                  <img
                    src={instagram}
                    alt="instagram"
                    width="24"
                    className="d-inline-block align-top mr-3"
                  />
                </a>
                <a href="https://linkedin.com/">
                  <img
                    src={linkedin}
                    alt="linkedin"
                    width="24"
                    className="d-inline-block align-top mr-3"
                  />
                </a>
                <a href="https://youtube.com/">
                  <img
                    src={youtube}
                    alt="youtube"
                    width="28"
                    className="d-inline-block align-top"
                  />
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
