import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import scrollTo from "gatsby-plugin-smoothscroll"

// Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Jumbotron from "react-bootstrap/Jumbotron"
import Button from "react-bootstrap/Button"

// images
import graduates from "../images/graduates.svg"
import square from "../images/sqr.png"
import study from "../images/study-uk-education-advisors_certificate_of_achievement.png"

// css
import "../components/index.scss"

export default function Home() {
  return (
    <Layout>
      <SEO title="Saldrey Study Compass" />
      <Jumbotron>
        <Container className="pt-5">
          <Row>
            <Col className="movecenter">
              <h1>Study abroad with ease</h1>
              <p>
                Get impartial counselling and application services for
                Universities and Colleges globally.
              </p>
              <Button
                variant="primary"
                size="lg"
                onClick={() => scrollTo("#contact")}
              >
                Contact Us
              </Button>
            </Col>
            <Col>
              <div className="d-flex justify-content-center">
                <img
                  src={graduates}
                  alt="saldrey study compass logo"
                  //width="100"
                  className="d-inline-block align-top graduate"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <div className="tal">
        <Container className="fixes" id="about">
          <Row className="#aboutBridge">
            <Col lg={4}>
              <img
                src={square}
                alt="saldrey study compass logo"
                //width="100"
                className="d-inline-block align-top"
              />
            </Col>
            <Col lg={8}>
              <h6>ABOUT US</h6>
              <h2>
                Your education
                <br />
                solution partner
              </h2>
              <p>
                Our team of experienced experts share a passion for supporting
                students with finding the right education solution. We see our
                role as that of enabling students, so they feel confident that
                they are making informed, objective decisions about the right
                school or university for themselves Our diverse team of experts
                offer students both from Nigeria and around the world advice in
                all areas of education. We offer students support and guidance
                with making the right choices at all stages of education. We
                foster close working relationships with 100’s of schools and
                educational institutions around the world, maintaining a busy
                programme of year-round school and university visits, to keep
                our knowledge up to date. Sharing this knowledge and experience
                is what enables our clients to feel confident they are making
                the right decisions for their future.
              </p>
            </Col>
          </Row>
          <Row className="vismi">
            <Col>
              <h1>Vision</h1>
              <h2 className="strip">
                To lead the charge in providing a highly educated leadership and
                contribute to the overall development of the youth in society.
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1>Mission</h1>
              <h2 className="strip">
                To be the foremost Education provider in the World.
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="institu">
        <Container className="bottomfix">
          <Row>
            <Col className="instituCard" xs={12} md={8}>
              <h2>Institutions</h2>
              <p>
                We have and are building one of the largest and most diverse
                exchange relationships with Universities in the UK, Ireland,
                USA, Canada ,Germany, Sweden and
                Australia with partnerships with more than 200 universities
                and institutions across them all. Our Relationships with these
                institutions gives us an edge over other agencies, as we deal
                directly with the school to get whatever information necessary
                to make your admission process faster and easier.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="certify" id="cert">
        <Container>
          <Row className="py-5">
            <Col className="d-flex align-items-center">
              <div className="kickin">
                <h2>Certified to serve you.</h2>
                <Button
                  variant="warning"
                  size="lg"
                  onClick={() => scrollTo("#contact")}
                >
                  Contact Us
                </Button>
              </div>
            </Col>
            <Col>
              <div className="d-flex justify-content-center">
                <img
                  src={study}
                  alt="certificate"
                  className="d-inline-block align-top certificate"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
